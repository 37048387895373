.modal-edit-profile-container {
  position: absolute;
  right: 2%;
  top: 8%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 0 3px 1px var(--mast-blue);
  padding: 30px 6px;
  border-radius: 8px;
  background: bottom;
}
.modal-edit-btn {
  cursor: pointer;
  color: var(--mast-blue);
  font-weight: 500;
  transition: color 0.4s ease;
}
.modal-edit-btn:hover {
  color: var(--main-gray);
}
.messages_container {
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 2%;
  transform: translate(-50%, 0);
  width: 80vw;
  max-width: 700px;
  min-width: 400px;
}
.item_message {
  position: relative;
  background: var(--myGrey);
  margin-bottom: 20px;
  padding: 10px 15px;
  color: white;
  border-radius: 8px;
  /* border: 3px solid var(--mast-yellow); */
  box-shadow: 0 0 6px 3px var(--mast-yellow);
}
.item_message-closed {
  cursor: pointer;
  position: absolute;
  right: -6px;
  top: -9px;
  color: var(--mast-yellow);
  display: inline-block;
  /* width: 18px;
  height: 18px;*/
  padding: 0 5px 1px 6px;
  border-radius: 50%;
  background: var(--myGrey);
  box-shadow: 0 0 4px 2px var(--mast-yellow);
  transition: all 0.4s ease-in;
  }
  .item_message-closed p {
    text-align: center;
  }
.item_message-closed:hover {
  background: var(--mast-yellow);
  color: var(--modal_grey);
}

