input,
textarea {
  outline: none;
}

.container {
  position: relative;
  max-width: 1450px;
  width: 100%;
  margin: auto;
  height: 100vh;
  /* overflow: hidden; */
}
.block-container {
  position: relative;
  width: 100%;
}

.card-title {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  /* margin: 20px 0 50px; */
  margin: 3% 0;
}
.text-start {
  text-align: start;
}
.card-container {
  position: absolute;
  top: 50%;
  left: 50%;
  background: white;
  transform: translate(-50%, -50%);
  padding: 0 20px 50px;
  width: 100%;
  max-width: 1100px;
  min-width: 350px;
  height: 70vh;
  /* border: 1px solid; */
  box-shadow: -4px 0 7px 1px black;
  border-radius: 8px;
  animation: animation_show_block 1s forwards;
}
.icon-edit {
  width: 32px;
}
.container-edit-btn {
  position: relative;
  cursor: pointer;
  color: var(--mast-blue);
  font-weight: 400;
  font-size: 14px;
}
.edit-btn-text {
  position: absolute;
  padding: 3px 5px;
  border-radius: 3px;
  color: aliceblue;
  background: var(--modal_grey);
  visibility: hidden;
  text-wrap: nowrap;
}
.container-edit-btn:hover > .edit-btn-text {
  visibility: inherit;
}
.card-text {
  font-size: 19px;
}
.card-text-item {
  min-height: 30px;
  margin: 0 0 5px 0;
  font-size: 15px;
}
.card-text-item_v2 {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.mrg-top-30 {
  margin-top: 30px;
}
.mrg-btm-50 {
  margin-bottom: 50px !important;
}
.mrg-top-100 {
  margin-top: 100px;
}
.mrg-top-50 {
  margin-top: 50px;
}
.mrg-btm-20 {
  margin-bottom: 20px;
}
.mrg-btm-10 {
  margin-bottom: 10px !important;
}
.mrg-top-12 {
  margin-top: 12px;
}
.card-input {
  width: 140px;
}
.container-page-by-page-nav {
  display: flex;
  /* margin: 10px auto; */
  align-items: center;
  justify-content: center;
  background: var(--mast-blue);
}
.inp-page-by-page-nav {
  width: 22px;
  text-align: center;
  margin: 0 8px;
  /* background: rgba(248, 243, 243, 0.452); */
  border-radius: 5px;
  border: 1px solid var(--mast-blue);
}
.main-page {
  display: flex;
  background: #ffffff;
  /* height: 98vh; */
  animation: animation_move_list 0.6s forwards;
  /* overflow-y: auto; */
  padding: 0 2%;
  /* width: 100vw; */
  flex-direction: column;
}
.main-title {
  font-size: 24px;
  text-align: start;
  /* margin-bottom: 15px; */
}
.container-list-this {
  /* padding-left: 10px; */
  /* height: 68vh; */
  overflow-y: auto;
}
.btn-page-by-page-nav {
  cursor: pointer;
  width: 15px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
}
.posi-abs {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px 1px #00000070;
  border-radius: 3px;
  background: #ffffff;
  height: 100%;
  /* width: 100vw; */
  min-height: 500px;
  /* margin: 0 0 10px; */
  padding: 0 15px;
  animation: animation_move_list 0.6s forwards;
  overflow-y: auto;
  overflow-x: hidden;
}
.posi-abs-mini {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: #ffffff;
  height: 100%;
  width: 100%;
  padding: 20px;
}
.active-number-page {
  color: var(--mast-yellow);
}
.container-item-el {
  display: flex;
  height: 42px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid rgb(212, 206, 206);
  transition: background 0.1s ease-in;
  align-items: center;
  padding: 5px 0;
  border-radius: 0px;
  font-size: 15px;
}
.container-item-el:hover {
  color: var(--main-blue-1);
  border-radius: 0px;
}
.text-table {
  font-weight: 700;
  /* border-bottom: 1px solid rgb(212, 206, 206); */
  text-align: start;
  font-size: 15px;
  color: #727272;
  padding-left: 1%;
}
.text_table_v2 {
  position: relative;
  font-weight: 400;
  border-bottom: 1px solid rgb(212, 206, 206);
  font-size: 13px;
  color: #727272;
  display: flex !important;
  justify-content: space-around;
  transition: all 0.4s ease;
  align-items: center;
}
.text_table_v2:hover {
  color: #ffffff;
  background: var(--mast-yellow);
}
.text_table_v3 {
  position: relative;
  font-weight: 400;
  font-size: 13px;
  color: #727272;
  display: flex !important;
  justify-content: center !important;
}
.container-list {
  /* width: 100%; */
  position: relative;
  /* padding: 0 15px; */
}
.item-cosmetic {
  padding: 0 12px;
}
.container-list-el {
  position: sticky;
  display: flex;
  height: 45px;
  /* z-index: 5; */
  /* margin-bottom: 25px; */
  /* max-width: 1300px; */
  width: 100%;
  background: white;
  top: 30px;
  left: 0;
  line-height: 50px;
  border-bottom: 1px solid rgb(212, 206, 206);
}
.active {
  color: var(--mast-yellow);
}
.form-page-by-bage {
  color: whitesmoke;
}
.btn-page-by-bage {
  cursor: pointer;
  background: whitesmoke;
  border-radius: 4px;
  border: 1px solid var(--mast-blue);
  color: var(--mast-blue);
  padding: 2px 8px;
  font-weight: 600;
  transition: all 0.3s ease-out;
}
.btn-page-by-bage:hover {
  color: var(--mast-yellow);
}
.card-text-container {
  font-size: 15px;
  padding: 0 25px;
}
.card-flex-container {
  display: flex;
  justify-content: flex-start;
}
.flx-container-btn-right-position {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-right: 20px;
}
.card-flex-container_v2 {
  width: 100%;
  /* max-width: 1388px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 200px;
  margin-right: 2vw;
}
.nav-card-btn {
  cursor: pointer;
  /* border: 1px solid #0000004d; */
  /* border-radius: 8px; */
  /* background: var(--mast-blue); */
  display: inline-block;
  text-align: start;
  font-weight: 500;
  color: black;
  font-size: 18px;
  border-bottom: 1px solid var(--main-blue-1);
  /* height: 55px; */
  padding: 8px 5px;
  margin-bottom: 4px;
}
.nav-card-btn:hover {
  color: var(--main-blue-1);
  /* color: white; */
}
.nav-card-btn-active {
  cursor: pointer;
  /* border: 1px solid #0000004d; */
  /* border-radius: 8px; */
  /* background: var(--mast-blue); */
  display: inline-block;
  text-align: start;
  font-weight: 500;
  color: var(--main-blue-1);
  border-bottom: 1px solid var(--main-blue-1);
  font-size: 18px;
  /* height: 55px; */
  padding: 8px 5px;
  margin-bottom: 4px;
}
.card-text-v2 {
  display: inline-block;
  width: 85px;
}
.margin-title {
  margin: 5% 0 3% 0;
}
.card-label-span {
  display: inline-block;
  width: 150px;
}
.card-label {
  width: 100%;
  margin-bottom: 20px;
}
.comment {
  resize: none;
  display: block;
  width: 100%;
  min-width: 600px;
  height: 60%;
  min-height: 100px;
  border: 1px solid var(--main-blue-1);
  margin-top: 8px;
  border-radius: 4px;
}
.btn-log-out {
  cursor: pointer;
  position: absolute;
  bottom: 70px;
}
.btn-log-out:hover {
  color: var(--main-gray);
}
.title-message {
  color: #00000078;
  text-align: center;
  margin-top: 2%;
}
.flex-alligment {
  display: flex;
  align-items: center;
}
.flx-sb {
  display: flex;
  justify-content: space-between;
}
.cls-list {
  padding-top: 40px;
}
.reset-posi {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.w400 {
  width: 400px;
}
.w600 {
  width: 600px;
}
.clm_50 {
  height: 100%;
  width: 50%;
}
.right_0 {
  position: absolute;
  right: 0;
}
.tabs_lgt_grey_border {
  border: var(--lgt-grey-border);
}
.padding_4_2 {
  padding: 4px 2px;
}
.padding_7_5 {
  padding: 7px 5px;
}
.cr_p {
  cursor: pointer;
}
.list_text {
  font-size: 15px;
}
.flex_center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.grey_container {
  width: 100%;
  height: 100%;
  /* background: #00000012; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.li_title {
  margin-bottom: 2px;
  color: #727272;
}
.input_nmb {
  max-width: 45px;
  background: #00000011;
  height: 34px;
  text-align: end;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  border-bottom: solid 1px var(--main-blue-1);
}
.error {
  color: var(--error);
  font-weight: 400;
}
.title_grey {
  color: var(--title_grey);
  font-size: 14px;
}
.hidden {
  display: none;
}
.container_card {
  position: relative;
  padding: 2% 1%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10%;
  max-width: 1200px;
}
.container_1366 {
  max-width: 1366px;
}
.title_container_btn_edit {
  display: flex;
  height: 70px;
  font-size: 18px;
  align-items: center;
  gap: 15px;
  justify-content: center;
}
.submenu_nav {
  display: flex;
  gap: 4vw;
  padding: 2vh 0 2vh 10px;
}
.text_12 {
  font-size: 11px;
  font-weight: 500;
  color: #727272;
}
.color-mast-blue-1 {
  color: var(--main-blue-1);
}
.no_right_text {
  color: #727272;
  font-size: 14px;
}
.mrg15_0 {
  margin: 15px 0;
}
