@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}
.App {
  text-align: center;
}
* {
  list-style: none;
  font-family: Montserrat;
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: black;
  text-decoration: none;
}

ul {
  padding: 0;
}

textarea {
  resize: none;
}

html,
iframe,
body {
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("./layout.css");
@import url("./login.css");
@import url("./header.css");
@import url("./var.css");
@import url("./employee.css");
@import url("./company.css");
@import url("./public.css");
@import url("./tools.css");
@import url("./modal.css");
@import url("./soft.css");
@import url("./loader.css");
@import url("./add_card.css");
@import url("./animation.css");
@import url("./media.css");
@import url("./UI.css");
@import url("./test.css");
@import url("./lic.css");
@import url("./report.css");
@import url("./alert.css");
@import url("./subscription.css");
@import url("./admin.css");
@import url("./analytic.css");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
