.item-employee-info {
  width: 22%;
}
.employee_card_left_text {
  width: 76px;
  display: inline-block;
}
.update_pass_profile_container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  margin: 15px 0 10px;
}
