.cls-tools {
    /* margin: 15px 10px 0 0; */
    position: sticky;
    z-index: 2;
    right: 0;
    top: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 2%;
    width: 100%;
    background: white;
    justify-content: flex-end;
}
.inp-search-tools {
  height: 38px;
  width: 300px;
  border: 1px solid var(--main-gray);
  border-radius: 6px;
}
.exportToExcel {
  margin-right: 5px;
    display: inline-block;
    cursor: pointer;
    border: var(--lgt-grey-border);
    padding: 2px 9px;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.4s ease-in;
    line-height: 150%;
    min-width: 40px;
    text-align: center;
    color: green;
    min-height: 30px;
}
.exportToExcel:hover {
  color: white;
  background: green;

}
.inp-search-tools:focus {
  outline: none !important;
}
