.item-company-name {
  display: inline-block;
  max-width: 400px;
  width: 35%;
  border: none;
}
.item-company-inn {
  display: inline-block;
  width: 10%;
  min-width: 150px;
  border: none;
}
.item-company-address {
  display: inline-block;
  width: 55%;
  font-size: 14px;
  border: none;
}
.card-company-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 20px 50px;
  width: 80vw;
  /* max-width: 600px; */
  min-width: 350px;
  /* border: 1px solid; */
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.699);
  border-radius: 8px;
  background: rgba(128, 128, 128, 0.11);
}
.card-company-inn_kpp {
  width: 300px;
  text-align: end;
}
.card-company-item-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.container-list-this-company {
  width: 100%;
}
.item-ul-production {
  cursor: pointer;
}

.item-production-text-container:hover {
  color: var(--main-blue-1);
}
.fill-inn {
  cursor: pointer;
  height: 32px;
  white-space: nowrap;
  font-size: 13px;
  color: var(--mast-blue);
  transition: all 1s ease-in;
}
.fill-inn:hover {
  text-decoration: underline;
}
.fill-inn-card {
  cursor: pointer;
  color: var(--mast-blue);
  border: 1px solid var(--mast-blue);
  padding: 3px 7px;
  border-radius: 5px;
  margin-left: 1%;
  transition: all 0.6s ease-in;
}
.fill-inn-card:hover {
  background: var(--mast-blue);
  color: azure;
}
.container-info-production {
  max-width: 75%;
  width: auto;
}

.card-text-inn-search {
  display: flex;
  align-content: center;
}
.item-production-text-container {
  padding: 10px 0 0 10px;
  border-radius: 3px;
}
.company_container_submenu {
  display: flex;
  gap: 5vw;
  padding: 0.2% 0 0.2% 2%;
  border-radius: 2px;
  width: 100%;
  justify-content: space-between;
}
.company_container_submenu_nav {
  display: flex;
  gap: 5vw;
}
.production-container-card-company {
  position: relative;
  height: 280px;
  overflow-y: auto;
  /* border: var(--lgt-grey-border); */
}
.info_company {
  border: var(--lgt-grey-border);
  height: 100%;
  padding: 1%;
}
.edit_number_ur_company_container {
  background: #fff;
  padding: 50px 30px;
}
.flex-container_card_company_block_lic {
  width: 50%;
  height: 124px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  border: var(--lgt-grey-border);
  padding: 15px 10px;
  border-radius: 4px;
}
.container_card_company_block_number_company {
  display: flex;
  width: 50%;
  height: 124px;
  border: var(--lgt-grey-border);
  padding: 15px 10px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-around;
}
.company_card_block_lic_text {
  margin-bottom: 3px;
  font-size: 14px;
}
.number_company_block_set {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sub_action_client_container {
  text-wrap: nowrap;
  background: var(--myGrey);
  padding: 5px 18px;
  color: aliceblue;
}
.sub_action_client_container li {
  font-size: 17px;
  margin: 15px 0;
  letter-spacing: 1px;
  transition: all 0.4s ease-in;
}
.sub_action_client_container li:hover {
  color: var(--mast-yellow);
}
.sub_action_client_container_border {
  z-index: 2;
  position: absolute;
  display: block;
  right: 70%;
  width: 22vw;
  max-width: 350px;
  min-width: 300px;
  border: 10px solid var(--myGrey);
  border-radius: 6px;
  box-shadow: 0 0 6px 3px var(--mast-yellow);
}
.sub_action_client_closed {
  position: absolute;
  right: -20px;
  top: -36px;
  color: var(--mast-yellow);
  display: inline-block;
  /* width: 18px;
  height: 18px;*/
  padding: 0 5px 1px 6px;
  border-radius: 50%;
  background: var(--myGrey);
  box-shadow: 0 0 4px 2px var(--mast-yellow);
  transition: all 0.3s ease-in;
}
.sub_action_client_closed:hover {
  color: white !important;
  background: var(--mast-yellow);
}
/* Фильтер по клиентам */
.client_filterbar_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
  /* min-width: 500px; */
}
.client_filterbar_show_all_client_container,
.client_filterbar_show_no_production_container {
  position: relative;
}
.client_filterbar_show_all_client_container:hover
  > .client_filterbar_show_all_client_span,
.client_filterbar_show_no_production_container:hover
  > .client_filterbar_show_no_production_span {
  visibility: inherit;
}
.client_filterbar_show_all_client_img {
  width: 24px;
  cursor: pointer;
}
.client_filterbar_show_all_client_span {
  cursor: pointer;
  text-wrap: nowrap;
  position: absolute;
  top: -28px;
  left: -220px;
  padding: 3px 5px;
  border-radius: 3px;
  color: aliceblue;
  background: var(--modal_grey);
  visibility: hidden;
}
.client_filterbar_show_no_production_span {
  cursor: pointer;
  text-wrap: nowrap;
  position: absolute;
  top: -28px;
  left: 80px;
  padding: 3px 5px;
  border-radius: 3px;
  color: aliceblue;
  background: var(--modal_grey);
  visibility: hidden;
}
.client_filterbar_show_period_container {
  position: relative;
}
.client_filterbar_show_period_span {
  cursor: pointer;
  text-wrap: nowrap;
  position: absolute;
  top: -28px;
  left: 0px;
  padding: 3px 5px;
  border-radius: 3px;
  color: aliceblue;
  background: var(--modal_grey);
  visibility: hidden;
}
.client_filterbar_show_period_container:hover
  > .client_filterbar_show_period_span {
  visibility: visible;
}
.client_filterbar_production {
  cursor: pointer;
  text-wrap: nowrap;
  padding: 3px 5px;
  border-radius: 3px;
  color: aliceblue;
  background: var(--modal_grey);
}
.client_filterbar_show_no_production_span {
  left: -205px;
}
.title_container_production {
  display: flex;
  justify-content: space-between;
}
.title_production_btn_container {
  display: flex;
  gap: 15px;
  align-items: stretch;
}
.item-production-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-production-filter-img {
  cursor: pointer;
  width: 26px;
  height: 26px;
}
.production-title-bar_container {
  position: sticky;
  width: 100%;
  top: 0;
  background: #fff;
  padding: 7px 10px 0;
}
.new_company_inn_block {
  display: flex;
  justify-content: space-between;
  height: 20px;
}
.bar_filter_client_company {
  padding: 0 1.5%;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  align-items: center;
}
.company_card_block_subscription_label {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}
