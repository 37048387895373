.rep_lic_list_name {
  align-items: center;
  width: 60%;
  font-size: 14px;
  min-width: 200px;
}
.rep_list_inn {
  align-items: center;
  width: 20%;
  font-size: 14px;
}
.rep_lic_counter {
  align-items: center;
  width: 5%;
  font-size: 14px;
}
.rep_list_valid_period_days {
  align-items: center;
  width: 15%;
  font-size: 14px;
}
.container_bar_all_report_lic {
  margin: 2px 1px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.title_report {
  font-weight: 500;
  font-size: 24px;
  margin: 20px 0;
}
