.login-container {
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  min-width: 350px;
  max-width: 550px;
  /* border: 1px solid; */
  border-radius: 8px;
  padding: 2vh 20px 6vh;
  box-shadow: 0px 2px 6px 3px #98abb3c2;
}
.login_reg-component {
  position: relative;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.login_reg-input {
  font-size: large;
  border: none;
  border-bottom: 1px solid var(--mast-blue);
  width: 100%;
  max-width: 600px;
  min-width: 200px;
}
.login_reg-input::-webkit-outer-spin-button,
.login_reg-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background: #0a377e;
  margin: 0;
}
.login-label {
  width: 95%;
  margin: 0 auto;
}
.login-btn-container {
  width: 90%;
  display: flex;
  justify-content: flex-start;
}
.login-btn-login {
  cursor: pointer;
  background: var(--mast-blue);
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  color: aliceblue;
  font-weight: 600;
  transition: all 0.3s ease-in;
}
.login-btn-login:hover {
  color: var(--mast-yellow);
}
.login-btn-reg {
  /* color: #68b6f9; */
  color: var(--mast-blue);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.3s ease-in;
}
.login-btn-reg:hover {
  text-decoration: none;
  color: var(--mast-yellow);
  
}
.msg-log {
  /* position: absolute; */
  color: red;
  font-weight: 700;
}

.login-label-span {
  display: inline-block;
  width: 77px;
}
.login-header {
  height: 6.5vh;
  min-height: 45px;
  background: var(--main-blue-1);
  display: flex;
  justify-content: center;
  padding: 0 5%;
  align-items: center;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-size: 30px;
  font-family: serif;
}
.login_title {
  color: #8a8c8c;
  font-weight: 400;
}
.grey_text_span_1 {
  margin-top: 10px;
  font-size: 13px;
  color: #6d6d6db0;
}
