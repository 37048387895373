.list-container-soft {
  /* padding: 1% 3% 2%; */
  height: 75%;
  overflow-y: auto;
}
.flex-space-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  align-items: center;
}
.card-flex-container-soft {
  max-width: 1300px;
  margin: 10px 1px;
  /* background: linear-gradient(var(--main-lg-bl), var(--mast-blue)); */
  background: var(--main_blu_blk);

  border-radius: 4px 4px 0px 4px;
  cursor: pointer;
  padding: 5px 2px 2px 10px;
  color: aliceblue;
  /* color: var(--mast-yellow); */
}
.card-flex-container-soft:hover {
  /* box-shadow: 0 0 4px 1px var(--mast-blue); */
}
.soft-info-client {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: aliceblue;
  color: black;
  padding: 12px 5px;
  border-radius: 3px 0 0 0;
}
.col-1-soft {
  width: 28%;
}
.row-soft {
  margin-bottom: 5px;
}
.page-nav-soft {
  background: var(--mast-blue);
}
.row-label-soft {
  display: block;
  margin: 30px 0;
}
.factory-soft-cls {
  border: 1px solid var(--mast-blue);
  border-radius: 3px;
  color: var(--mast-blue);
  width: 38px;
  text-align: end;
  display: inline;
  position: relative;
  bottom: 3px;
  font-size: 16px;
}
.soft-container {
  font-size: 18px;
  font-weight: 500;
}
.list-dmc-container {
  position: relative;
  bottom: 3px;
  border: 1px solid var(--mast-blue);
  border-radius: 3px;
  color: var(--mast-blue);
  font-weight: 600;
  font-size: 15px;
}
.soft-select-messages-container {
  font-weight: 400;
  font-size: 28px;
  padding: 0 35px;
  color: #23619285;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44vh;
}
.soft-client-header-text {
  display: inline-block;
  width: 220px;
  font-weight: 600;
  font-size: 17px;
}
.soft-client-header-subtext {
  font-weight: 500;
  font-size: 14px;
  padding-right: 5px;
}
.btn-filter-soft-container {
  display: flex;
  justify-content: flex-end;
  gap: 1%;
  padding: 15px 20px;
  align-items: center;
}
.key-btn {
  cursor: pointer;
  width: 32px;
}

.visible-container-soft-key {
  display: block;
  /* position: absolute;
  right: 2%;
  top: 3%;
  display: flex;
  cursor: pointer; */
  /* display: flex; */
  position: relative;
  cursor: pointer;
  color: var(--mast-blue);
  font-weight: 400;
  font-size: 14px;
  /* gap: 5px; */
}
.visible-text-soft-key {
  position: absolute;
  right: 55px;
  top: -15px;
  padding: 4px 7px;
  border-radius: 3px;
  color: aliceblue;
  background: var(--modal_grey);
  visibility: hidden;
  display: inline-block;
  text-wrap: nowrap;
}
.visible-container-soft-key:hover > .visible-text-soft-key {
  visibility: inherit;
}
.modal_soft_lic_container {
  padding: 2px 20px 5px;
  background: aliceblue;
}

/* Новые */

.soft_list_name {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: 200px;
}
.soft_list_client_id {
  display: flex;
  align-items: center;
  width: 40%;
  min-width: 320px;
}
.soft_list_last_session {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  min-width: 210px;
}
.soft_list_software_version {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  min-width: 160px;
}
.soft_list_valid_action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  min-width: 100px;
}
.search_id_client {
  height: 55%;
  min-height: 25px;
  border: var(--lgt-grey-border);
  text-align: end;
  border-radius: 3px;
  padding-right: 5px;
}
.search_id_client:focus {
  outline: none !important;
}
.search_id_client_container {
  transition: all 0.4s ease;
  display: flex !important;
  justify-content: space-around !important;
}
.search_id_client_container:hover {
  color: #ffffff;
  background: var(--mast-yellow);
}
.soft_filter_version_container {
  width: 325px;
  display: flex;
  align-items: center;
  gap: 10px;
}
