.generation_subs_min_block_left {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 100%;
  /* flex: 0 0 70%; */
  height: 123px;
}
