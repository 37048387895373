@keyframes animation_show_block {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation_move_list {
  0% {
    margin-top: -1000px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes animation_logo_header {
  50% {
    transform: scale(0.95);
  }
}
