.lic_container_item {
  display: flex;
  cursor: pointer;
  transition: color 0.1s ease;
}
.lic_container_item_old {
  display: flex;
  cursor: pointer;
  transition: color 0.1s ease;
  color: var(--error);
}
.lic_container_item_hold {
  display: flex;
  cursor: pointer;
  transition: color 0.1s ease;
  color: #3588ccb5;
}
.lic_container_item:hover {
  color: var(--main-blue-1);
}
.lic_container_header {
  /* width: 100%; */
  display: flex;
  /* margin: 1vh 0 0; */
  height: 45px;
}
.lic_list_type {
  display: flex;
  align-items: center;

  width: 5%;
  min-width: 40px;
}
.lic_list_client {
  display: flex;
  align-items: center;

  width: 35%;
  min-width: 380px;
  /* min-height: 28px; */
}
.lic_list_data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12%;
  min-width: 90px;
}
.lic_list_days {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12%;
  min-width: 40px;
}
.modal-unlinc-lic-client {
  z-index: 2;
  display: flex;
  gap: 1%;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5vh 5vw;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border: var(--lgt-grey-border);
  justify-content: center;
}
.generation_lic_period_block {
  display: flex;
  justify-content: space-between;
  gap: 1%;
}
.generation_lic_container {
  width: 80%;
  min-width: 525px;
  max-width: 675px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.btn_active_period {
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
  background: var(--main-blue-1);
  padding: 4px 9px 0;
  border-radius: 6px;
  color: aliceblue;
  box-shadow: 0 0 4px 2px var(--main-blue-1);
  font-weight: 500;
  transition: all 0.4s ease;
  line-height: 150%;
  min-width: 40px;
  text-align: center;
  min-height: 30px;
}
.btn_deactive_period {
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
  border: var(--lgt-grey-border);
  /* background: var(--mast-yellow); */
  padding: 4px 9px 0;
  border-radius: 6px;
  /* color: aliceblue; */
  /* box-shadow: 0 0 5px 1px var(--mast-yellow); */
  font-weight: 500;
  transition: all 0.4s ease-in;
  line-height: 150%;
  min-width: 40px;
  text-align: center;
  color: var(--main-gray);
  min-height: 30px;
}
.generation_lic_text {
  width: 100%;
  height: 180px;
  padding: 15px 5px;
}
.generation_lic_min_block_left {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  flex: 0 0 70%;
  height: 123px;
}
.generation_lic_min_block_right {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  flex: 0 0 29%;
}
.icon_calendar {
  cursor: pointer;
  height: 42px;
  margin: 0 8px;
}

.calendar {
  position: relative;
  border: solid 1px #00000038;
  border-radius: 4px;
  font-size: 15px;
  text-align: center;
  margin-left: 10px;
}
.calendar::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
.generation_lic_btn_period {
  display: flex;
  align-items: center;
  /* width: 60%; */
  height: 40px;
  justify-content: space-evenly;
}
.licensing_card_block {
  display: flex;
  flex-direction: column;
}
.list_free_lic {
  display: inline-block;
  width: 125px;
  min-height: 35px;
  text-align: center;
}
.list_free_lic_name {
  display: inline-block;
  width: 670px;
  min-height: 35px;
  text-align: start;
}
.list_free_lic_container {
  margin-top: 40px;
  overflow-y: auto;
}
.lic_update_res {
  font-size: 13px;
  font-weight: 500;
  color: var(--error);
}
.card_company_block_lic_no_rigth {
  width: 50%;
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  border: var(--lgt-grey-border);
  padding: 15px 10px;
  border-radius: 4px;
  color: #727272;
}
