.header {
  position: fixed;
  z-index: 10;
  height: 100vh;
  min-width: 180px;
  /* background: var(--main-bl-gr); */
  background: var(--main-lg-bl);
  overflow: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 15px 15px 40px 15px; */
  box-shadow: 0 1px 5px 1px rgb(133, 132, 132);
  transition: all 0.4s ease-out;
}
.header__title-logo-container {
  display: flex;
  margin-bottom: 45px;
  text-align: center;
  align-items: flex-start;
}
.header__title-logo-dmc {
  color: aliceblue;
  font-size: 47px;
}
.header__title-logo-lk {
  color: aliceblue;
  font-size: 13px;
  font-weight: 700;
  margin-top: -8px;
}
/* .nav-container {
} */
.link-header {
  color: aliceblue;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  transition: color 0.3s ease-in;
}
.link-header:hover {
  color: var(--mast-yellow);
}
.nav-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.icon-profile {
  width: 40px;
  /* filter: opacity(0.8); */
  filter: invert(0.95);
}
.logo-header {
  width: 8vh;
  min-width: 60px;
}
.nav-login {
  width: 100%;
  display: flex;
  justify-content: center;
}
.lk-nav {
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lk-nav-name {
  color: rgba(240, 248, 255, 0.822);
  display: flex;
  flex-direction: column;
  font-weight: 500;
}
.lk-nav-name-active {
  display: flex;
  flex-direction: column;
  font-weight: 500;
}
.header_alert {
  position: relative;
  width: 26px;
}
.media_btn_header_container {
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: 91vh;
  width: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-lg-bl);
  border-radius: 0 6px 6px 0;
}
.media_btn_header {
  width: 12px;
  transition: all 0.1s ease-in;
  transform: rotate(180deg);
}
.header_icon_nav {
  width: 32px;
  height: 32px;
  filter: invert(1);
}

.header_icon_nav_active {
  width: 32px;
  height: 32px;
}
