.admin_sub_nav_container {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 45px 0;
  position: relative;
}
.group_category_role_block {
  width: 60%;
  min-width: 600px;
  max-width: 720px;
  /* background: var(--main-lg-bl); */
  /* border: 1px solid var(--main-lg-bl); */
  /* padding: 10px 5px; */
  /* margin: 0 auto 10px; */
}

.group_category_role_title {
  cursor: pointer;
  border-bottom: 1px solid var(--main-blue-1);
  /* border-radius: 4px; */
  /* background: #fdeeee; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  font-size: 20px;
  padding-left: 1%;
  color: var(--title_grey);
  /* color: var(--main-blue-1); */
}
.group_category_role_block_checkbox {
  /* background: white; */
  /* border: 1px solid var(--main-blue-1); */
  border-radius: 4px;
  padding: 12px 8px;
}
.admin_permission_select_container {
  position: relative;
  overflow-y: auto;
}
.role_item_text {
  display: inline-block;
  width: 95%;
  height: 25px;
}
.role_item_checkbox {
  height: 18px;
  width: 18px;
}
.admin_add_role_container {
  position: absolute;
  bottom: -35px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
}
.title_sticky_role_card {
  position: sticky;
  top: 0;
  height: 100%;
  /* max-width: 250px; */
}
.filter_bar-admin {
  border: var(--lgt-grey-border);
  display: flex;
  margin: 30px 0;
  /* justify-content: flex-end; */
  gap: 15px;
  padding: 5px 10px;
}
.filter_bar-filter_icon {
  height: 40px;
  cursor: pointer;
}
.role_card_btn_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 200px;
}
.item_user_admin_name {
  width: 23%;
  display: inline-block;
}
.item_user_admin_email {
  width: 20%;
  display: inline-block;
}
.item_user_admin_10 {
  width: 14%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.container_title_user-item-el {
  width: 100%;
}
.reg_form {
  display: flex;
  /* display: block; */
  /* margin: auto; */
  /* text-align: center; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  gap: 5%;
}
.item_role {
  width: 90%;
}
.item_role_icone_remove {
  padding-right: 20px;
  background: url("../img/icons/remove.png");
  background-size: cover;
  width: 32px;
  height: 32px;
  transition: all 0.2s ease-in;
}
.item_role_icone_remove:hover {
  padding-right: 20px;
  background: url("../img/icons/remove_red.png");
  background-size: cover;
  width: 32px;
  height: 32px;
}
.item_role_container {
  height: 42px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid rgb(212, 206, 206);
  transition: background 0.1s ease-in;
  align-items: center;
  padding: 5px 15px 5px 0;
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
}
.item_role_container:hover {
  color: var(--main-blue-1);
}
.item_client_id_admin_client {
  width: 40%;
  min-width: 315px;
  max-width: 400px;
}
.item_client_id_admin_name {
  width: 18%;
  min-width: 225px;
}
.item_client_id_admin_company {
  width: 42%;
}
.item_client_id_admin_btn-header {
  width: 10%;
}
.item_client_id_admin_btn {
  width: 10%;
  transition: all 0.4s ease;
}
.item_client_id_admin_btn:hover {
  color: red;
}
.modal-text-admin-panel {
  margin: 0 10% 5%;
  line-height: 2;
}
.client_id_admin_header {
  display: flex;
  width: 30%;
  min-width: 315px;
  max-width: 400px;
}
.role_item_admin_superuser_title {
  border-bottom: 1px solid var(--title_grey);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  font-size: 18px;
  padding-left: 1%;
  color: var(--title_grey);
  margin-top: 25px;
}
.role_item_admin_superuser_title_user {
  padding-left: 1%;
  color: var(--title_grey);
  margin-top: 8px;
}
.title_sticky_role_card_name {
  margin-right: 10px;
}
.role_card_btn_container_aply {
  margin-top: 25px;
}
