/* Большие кнопки */
.btn_func_container {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 38px;
  padding: 0 6px;
  border-radius: 4px;
  border: solid 1px var(--main-blue-1);
  font-size: 17px;
  font-weight: 400;
  color: var(--main-blue-1);
  align-items: center;
  flex-wrap: nowrap;
  min-width: 140px;
  max-width: 170px;
  text-align: center;
  transition: all 0.6s ease-in;
  background: var(--main-blue-1);
}
.btn_func_container span {
  z-index: 1;
  display: flex;
}
.add-icon-UI {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  background: var(--main-blue-1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-blue-1);
  margin-right: 5px;
}
.btn_func_container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 4px;
}

.btn_func_container:hover,
/* .btn_func_container_v2:hover, */
.btn_func_container_v4:hover > .btn_func_container_v4_span,
.btn_closed_ver_1:hover > .btn_closed_ver_1_span {
  box-shadow: none;
  animation: filling_btn_color_text 0.4s forwards ease-in;
}
.btn_func_container:hover:after,
/* .btn_func_container_v2:hover:after, */
.btn_func_container_v4:hover:after,
.btn_closed_ver_1:hover:after {
  box-shadow: none;
  animation: filling_btn 0.5s forwards ease;
}
.btn_func_container:hover > .add-icon-UI {
  animation: filling_btn_color_icon 0.15s forwards ease-in;
}

/* Небольшая кнопка версия 2 */

.btn_func_container_v2 {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  /* height: 38px; */
  padding: 5px 3px;
  border-radius: 4px;
  border: none;
  /* border-right: solid 1px var(--main-blue-1); */
  /* border-bottom: solid 1px var(--main-blue-1); */
  font-size: 15px;
  font-weight: 400;
  color: var(--main-blue-1);
  align-items: center;
  flex-wrap: nowrap;
  min-width: 140px;
  max-width: 160px;
  /* text-align: end; */
  /* transition: all 0.4s linear; */
  /* background: var(--main-blue-1); */
  box-shadow: 0px 2px 2px 4px #aacfed;
}

.btn_func_container_v2:hover {
  margin-top: 2px;
  box-shadow: 0px 1px 1px 3px #aacfed;
}

.btn_func_container_v2 span {
  z-index: 1;
  display: flex;
}
/* .btn_func_container_v2::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  /* background: rgb(227 250 255); */
/* box-shadow: 0px 2px 1px 1px #aacfed; */
/* box-shadow: 0px 2px 2px 4px #aacfed;
  border-radius: 4px;
} */

/* Кнопка исполнения версии 3 */

.btn_func_container_v3 {
  cursor: pointer;
  padding: 5px 3px;
  border-radius: 4px;
  border: none;
  font-size: 15px;
  font-weight: 400;
  color: var(--main-blue-1);
  min-width: 120px;
  background: none;
  transition: all 0.6s ease-in;
}

.btn_func_container_v3:hover {
  color: #3588cc;
}

/*  */
.btn-tag {
  cursor: pointer;
  border: 1px solid var(--mast-blue);
  border-radius: 3px;
  color: var(--mast-blue);
  transition: all 0.3s ease-in;
  padding: 3px 5px;
}
.btn-tag-active {
  cursor: pointer;
  border: 1px solid var(--mast-blue);
  background-color: var(--mast-blue);
  box-shadow: 0 0 5px 1px var(--mast-blue);
  border-radius: 3px;
  color: aliceblue;
  transition: all 0.3s ease-in;
  padding: 3px 5px;
}
.btn-tag:hover {
  background-color: var(--mast-blue);
  color: aliceblue;
  box-shadow: 0 0 5px 1px var(--mast-blue);
}
.blue-text {
  color: var(--mast-blue);
}
.btn_filter_ver_1 {
  width: 30px;
  position: absolute;
  right: 0;
}
.my_btn_check_active_false {
  margin: 0 8px 0 0;
  cursor: pointer;
  display: block;
  width: 45px;
  min-width: 45px;
  height: 22px;
  border: 1px solid #acacac78;
  border-radius: 14px;
  position: relative;
  background: #bcbbb940;
}
.my_btn_check_active_false_span {
  display: block;
  width: 16px;
  height: 16px;
  background: #acacac78;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 6%;
  transform: translate(0, -50%);
  transition: all 1s ease;
  /* animation: btn_check_off_span 1s forwards; */
}
.my_btn_check_active_false:hover > .my_btn_check_active_false_span {
  animation: btn_check_on_span 1s forwards;
}
.my_btn_check_active_span_1 {
  font-size: 13px;
  color: #80808080;
}

.my_btn_check_active_true {
  margin: 0 8px 0 0;
  cursor: pointer;
  display: block;
  width: 45px;
  min-width: 45px;
  height: 22px;
  border: 2px solid var(--main-blue-1);
  border-radius: 14px;
  box-shadow: 0 0 4px 1px var(--main-blue-1);
  position: relative;
  background: #8aa5ff40;
}
.my_btn_check_active_true_span {
  display: block;
  width: 16px;
  height: 16px;
  background: var(--main-blue-1);
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 57%;
  transform: translate(0, -50%);
  box-shadow: 0 0 3px 1px var(--main-blue-1);
  transition: all 1s ease;
  /* animation: btn_check_on_span 1s forwards; */
}
.my_btn_check_active_true:hover > .my_btn_check_active_true_span {
  animation: btn_check_off_span 1s forwards;
}
.my_btn_check_active_container_column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.my_btn_check_active_container_row {
  display: flex;
  align-items: center;
}

.container_title_edit {
  display: flex;
  align-items: center;
  gap: 3%;
}

.input_edit_v1 {
  font-size: 17px;
  border: none;
  border-bottom: solid 1px var(--main-blue-1);
  margin: 3px 15px 0 0;
  color: #727272;
}
.btn_func_container_v4 {
  position: relative;
  cursor: pointer;
  padding: 0;
  width: 26px;
  height: 26px;
  /* font-size: 32px;
  font-weight: 400;
  color: var(--main-blue-1); */
  border: 1px solid var(--main-blue-1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px var(--main-blue-1);
  transition: all 0.5s ease-in;
  background: var(--main-blue-1);
}

.btn_func_container_v4_span {
  position: absolute;
  z-index: 2;
  font-size: 32px;
  font-weight: 400;
  color: var(--main-blue-1);
}

.btn_closed_ver_1 {
  position: relative;
  cursor: pointer;
  padding: 0;
  width: 26px;
  height: 26px;
  border: 1px solid var(--main-blue-1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px var(--main-blue-1);
  transition: all 0.5s ease-in;
  background: var(--main-blue-1);
}

.btn_closed_ver_1_span {
  position: absolute;
  z-index: 2;
  font-size: 26px;
  font-weight: 400;
  color: var(--main-blue-1);
}

.btn_func_container_v4:hover {
  box-shadow: none;
}
.btn_func_container_v4:hover > .btn_func_container_v4_text {
  visibility: inherit;
}
.btn_func_container_v4_text {
  position: absolute;
  left: -160px;
  top: 0;
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 14px;
  color: aliceblue;
  background: var(--modal_grey);
  visibility: hidden;
}
.btn_func_container_v4::after,
.btn_closed_ver_1::after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 4px;
}

.btn_size_ver_1 {
  cursor: pointer;
  padding: 4px 6px;
  border: 2px solid var(--main-blue-1);
  border-radius: 5px;
  width: 28px;
  height: 28px;
  transition: all 0.3s ease-in-out;
}

.btn_size_ver_1::after {
  content: "\2922";
  font-size: 16px;
  height: 16px;
  display: block;
  color: var(--main-blue-1);
  transition: all 0.3s ease-in-out;
}
.btn_size_ver_1:hover {
  background: var(--main-blue-1);
  border: none;
}
.btn_size_ver_1:hover::after {
  font-size: 18px;
  margin-top: -2px;
  font-weight: 600;
  color: white;
}
.inp_soft_filter_version {
  margin-right: 5px;
  display: inline-block;
  font-size: 17px;
  padding: 4px 9px 0;
  border-radius: 6px;
  color: var(--main-blue-1);
  /* font-weight: 500; */
  transition: all 0.4s ease;
  line-height: 150%;
  min-height: 30px;
  border: none;
  border-bottom: 1px solid var(--main-blue-1);
  width: 90px;
}
.text_soft_filter_version {
  font-weight: 400;
  font-size: 15px;
  color: #727272;
}
.cls_visible_pass_icon {
  width: 21px;
  opacity: 0.65;
  cursor: pointer;
}
@keyframes filling_btn {
  100% {
    height: 0%;
  }
}
@keyframes filling_btn_color_text {
  100% {
    color: white;
  }
}
@keyframes filling_btn_color_icon {
  100% {
    color: white;
  }
}
@keyframes btn_check_off_span {
  0% {
    background: var(--main-blue-1);
    /* left: 57%;
    transform: translate(0, -50%); */
  }
  100% {
    background: #acacac78;
    /* left: 6%;
    transform: translate(0, -50%); */
  }
}
@keyframes btn_check_on_span {
  0% {
    background: #acacac78;
    /* left: 6%; */
  }
  100% {
    background: var(--main-blue-1);
    /* left: 57%;
    transform: translate(0, -50%); */
  }
}
