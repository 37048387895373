@media all and (max-width: 975px) {
  .fill-inn {
    position: absolute !important;
    right: 0 !important;
  }
  /* .card-flex-container_v2 {
    flex-direction: column;
  } */
}

@media all and (max-width: 1100px) {
  .card-title {
    font-size: 20px;
  }
  .container_card {
    flex-direction: column;
    gap: 0;
  }
  /* Страница компаний */
  .item-company-name {
    width: 60vw;
    max-width: none;
  }
  /* Страница карточки компании */
  .soft_list_name {
    min-width: 90px;
    width: 20vw;
  }
  .card-flex-container_v2 {
    flex-direction: column;
  }
  .clm_50,
  .flex-container_card_company_block_lic,
  .container_card_company_block_number_company {
    width: 90%;
    margin-left: 15px;
  }

  .generation_lic_container {
    min-width: 70%;
    align-items: center;
  }
  .generation_lic_min_block_left,
  .generation_lic_min_block_right {
    padding: 15px 2px !important;
  }
  .btn-container-new-company {
    margin-right: 0;
    align-items: center;
    justify-content: center;
  }
  .list_free_lic_name {
    width: 95px !important;
    font-size: 14px;
  }
  .list_free_lic {
    font-size: 13px;
    width: 90px;
  }
  .modal-unlinc-lic-client {
    padding: 5vh 5px !important;
  }
  .modal-unlinc-lic-client p {
    font-size: 14px;
  }
  .client_filterbar_container {
    min-width: 200px;
    width: 100%;
  }
  .company_container_submenu {
    padding: 1px;
    gap: 1%;
  }
  /*Страница сотрудников */
  .item-employee-info {
    width: 33%;
  }
  /* Отчёты */
  .rep_lic_list_name {
    font-size: 12px;
    min-width: 165px !important;
  }
  .rep_list_inn {
    min-width: 90px !important;
    font-size: 12px;
  }
  .rep_list_valid_period_days {
    width: 10% !important;
  }
  .rep_lic_counter {
    width: 10% !important;
  }
  .container_bar_all_report_lic {
    padding: 0;
    margin: 0;
  }
  .client_filterbar_show_period_container {
    width: 100px !important;
  }
  /* Панель админа */
  .item_user_admin_name,
  .item_user_admin_email {
    width: 40% !important;
  }
  .col-ul-new-card {
    width: 90%;
  }
  .filter_bar-admin {
    margin: 0;
  }
  .role_item_text {
    width: 85%;
    font-size: 11px;
  }
  .group_category_role_block {
    width: 100% !important;
    min-width: 0 !important;
  }
  .title_sticky_role_card {
    z-index: 2;
    width: 100%;
    background: white !important;
  }
}
@media all and (max-width: 470px) {
  .soft_list_valid_period_days {
    min-width: 60px;
    width: 60px;
  }
  .soft_list_software_version {
    min-width: 60px;
    width: 60px;
  }
  .soft_list_valid_action {
    width: 40px;
    min-width: 40px;
  }
  .soft_list_client_id {
    min-width: 72px;
    width: 40vw;
  }
}
@media all and (max-width: 1280px) {
}

@media all and (max-width: 600px) {
  * {
    font-size: 14px;
  }
  .main-page {
    padding: 0 !important;
    overflow-x: hidden;
  }
  .card-flex-container_v2 {
    align-items: flex-start;
  }
  /* Страница компаний */
  .inp-search-tools {
    height: 28px;
    width: 70%;
    border-radius: 4px;
  }
  .cls-tools {
    position: sticky;
    align-items: center;
    justify-content: flex-start;
  }
  .item-cosmetic {
    padding: 0 2px;
  }
  .item-company-name {
    font-size: 12px;
    overflow-wrap: anywhere;
  }
  .item-company-inn {
    width: none;
    min-width: none;
  }
  /* Страница карточки компании */
}
