/* .alert_error_15_day_container {

} */
.alert_error_container {
  /* width: 100%; */
  border: var(--lgt-grey-border);
  cursor: pointer;
  border-bottom: 1px solid rgb(212, 206, 206);
  transition: background 0.1s ease-in;
  align-items: center;
  margin: 0 5vw;
  border-radius: 4px;
}
.alert_error_text {
  padding: 10px 14px;
  /* border-bottom:var(--lgt-grey-border); */
}
/* .alert_error_container:hover {
  color: var(--mast-yellow);
} */
.alert_error_counter_header_container {
  z-index: 33;
  position: absolute;
  top: -10px;
  right: -13px;
  background: var(--error);
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert_error_counter_header_calc {
  position: relative;
  /* z-index: 2; */
  display: inline-block;
  font-size: 12px;
  color: white;
  /* padding: 12px !important; */
}
.alert_error_counter_header_icon_animation_on {
  width: 22px;
  position: relative;
  z-index: 1;
  animation: alert_on 2s infinite;
}

@keyframes alert_on {
  0% {
    rotate: 0deg;
  }
  40% {
    rotate: -20deg;
  }
  75% {
    rotate: 20deg;
  }
  100% {
    rotate: 0deg;
  }
}
