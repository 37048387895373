.add-item {
    cursor: pointer;
    border: 1px solid var(--mast-blue);
    border-radius: 4px;
    padding: 3px 5px;
    /* position: absolute; */
    display: flex;
    right: 2%;
    top: 3px;
    gap: 5px;
    align-items: center;
    color: var(--mast-blue);
    font-weight: 700;
    font-size: 20px;
    transition: all 0.8s;
  }
  .add-item:hover {
    background: var(--mast-blue);
    color: #ffffff;
  } 
  .add-item-card {
    cursor: pointer;
    background: none;
    border: 1px solid var(--mast-blue);
    border-radius: 4px;
    padding: 3px 5px;
    position: absolute;
    display: flex;
    right: 2%;
    top: 70px;
    gap: 5px;
    align-items: center;
    color: var(--mast-blue);
    font-weight: 700;
    font-size: 20px;
    transition: all 0.8s;
  }
  .closed-item-card {
    cursor: pointer;
    border: 1px solid var(--mast-blue);
    border-radius: 4px;
    padding: 3px 5px;
    position: absolute;
    display: flex;
    right: 2%;
    top: 120px;
    gap: 5px;
    align-items: center;
    color: var(--mast-blue);
    font-weight: 700;
    font-size: 20px;
    transition: all 0.8s;
  }
  .add-item-card:hover {
    background: var(--mast-blue);
    color: #ffffff;
  }
  .closed-item-card:hover {
    background: var(--mast-blue);
    color: #ffffff;
  }


  .btn-container-new-company {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 3px;
    margin-right: 44%;    
    align-items: center;
    height: 40px;
  }
  .add-new-card {
    cursor: pointer;
    border: 1px solid var(--mast-blue);
    border-radius: 4px;
    padding: 3px 5px;
    color: var(--mast-blue);
    font-weight: 700;
    font-size: 20px;
    transition: all 0.8s;
  }
  .add-new-card:hover {
    background: var(--mast-blue);
    color: #ffffff;
  }
  .add-item-container {
    height: 70%;
    padding: 0 50px;
  }

  .col-ul-new-card {
    width: 50%;
  }
