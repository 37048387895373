.modal-loader {
  background: #2e2d2d8f;
  width: 100%;
  height: 120%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.container-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.loader {
  --b: 12px; /* border thickness */
  --n: 12; /* number of dashes*/
  --g: 5deg; /* gap between dashes*/
  --c: var(--mast-blue); /* the color */
  width: 150px; /* size */
  /* height: 100px; */
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 2s infinite steps(var(--n));
}

.loader_v2 {
  text-align: center;
  position: relative;
  /* display: inline-block;
  text-align: center; */

  width: 25.5vw;
}
.loader_v2::before {
  display: inline-block;
  position: absolute;
  left: 5%;
  content: "МАСТ";
  font-weight: 900;
  font-size: 7vw;
  letter-spacing: 1vw;
  color: #6c798b2c;
  background-clip: text;
  background-color: var(--mast-blue);
  animation: loader_v2 2s infinite ease-in;
}

@keyframes loader_v2 {
  0% {
    width: 0%;
  }
  75% {
    width: 100%;
  }
  85% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
